import { post, get, put, deletee } from "./index";

export const getAllPermissions = (params) => {
  const request = {
    subUrl: `/permission`,
    params,
  };
  return get(request);
};

export const getPermissionByID = (id) => {
  const request = {
    subUrl: `/permission/${id}`,
  };
  return get(request);
};

export const addPermission = (data) => {
  const request = {
    subUrl: `/permission`,
    data,
    isJSON: true,
  };
  return post(request);
};

export const updatePermission = (data, id) => {
  const request = {
    subUrl: `/permission/${id}`,
    data: data,
    isJSON: true,
  };
  return put(request);
};

export const deletePermission = (id) => {
  const request = {
    subUrl: `/permission/${id}`,
  };
  return deletee(request);
};
