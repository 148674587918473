import { listingReducer } from "../reducers/utils/listingReducer";
import { getAllPortfolio } from "../../services/portfolio";

const { reducer, actions, types } = listingReducer({
  name: `portfolios`,
  getApi: getAllPortfolio,
});



const portfolios = reducer;
export { portfolios, actions, types };
