// import { currencies } from "utilities/utils";
const initialState = {
  token: null,
  user: null,
  uploads: null,
  login_type: null,
  user_permissions: [],
  otpCount: 0,
  resetMailID: null,
};
export const sessionTypes = {
  LOGIN_REQUEST: "session/LOGIN_REQUEST",
  LOGIN_SUCCESS: "session/LOGIN_SUCCESS",
  LOGIN_FAILURE: "session/LOGIN_FAILURE",
  SET_LOGIN_TYPE: "SET_LOGIN_TYPE",
  SET_USER_PERMISSION: "SET_USER_PERMISSION",
  SET_OTP_COUNT: "SET_OTP_COUNT",
  CLEAR_LOCAL_STATE: "CLEAR_LOCAL_STATE",
  SET_RESET_MAIL_ID: "SET_RESET_MAIL_ID",
};

export const actionTypes = {
  SET_UPLOAD_DETAILS: "SET_UPLOAD_DETAILS",
};
export const localStore = (state = initialState, action) => {
  switch (action.type) {
    case sessionTypes.LOGIN_SUCCESS: {
      const { token, user } = action.payload;
      return {
        ...state,
        user,
        token,
      };
    }
    case sessionTypes.SET_OTP_COUNT: {
      return {
        ...state,
        otpCount: action.payload,
      };
    }

    case sessionTypes.SET_RESET_MAIL_ID: {
      return {
        ...state,
        resetMailID: action.payload,
      };
    }
    case actionTypes.SET_UPLOAD_DETAILS: {
      return {
        ...state,
        uploads: action.payload,
      };
    }
    case sessionTypes.SET_LOGIN_TYPE: {
      return {
        ...state,
        login_type: action.payload,
      };
    }
    case sessionTypes.SET_USER_PERMISSION: {
      let permissions = action.payload.permissions;
      let permission = {
        permissions: {
          canUpload: false,
          canDownload: false,
          canViewContent: true,
          canAddContent: false,
          canDeleteContent: false,
          canPerformAllActivity: false,
        },
      };
      if (
        action?.payload?.role === "admin" ||
        action?.payload?.role === "staff"
      ) {
        permission = {
          permissions: {
            canUpload: true,
            canDownload: true,
            canViewContent: true,
            canAddContent: true,
            canDeleteContent: true,
            canEditContent: true,
            canPerformAllActivity: true,
          },
        };
      } else {
        // content_edit
        permission = {
          permissions: {
            canUpload:
              permissions.find((p) => p.feature_id === "upload")?.permission ===
              "1"
                ? true
                : false,
            canDownload:
              permissions.find((p) => p.feature_id === "download")
                ?.permission === "1"
                ? true
                : false,
            canViewContent:
              permissions.find((p) => p.feature_id === "content_view")
                ?.permission === "1"
                ? true
                : false,
            canAddContent:
              permissions.find((p) => p.feature_id === "content_add")
                ?.permission === "1"
                ? true
                : false,
            canEditContent:
              permissions.find((p) => p.feature_id === "content_edit")
                ?.permission === "1"
                ? true
                : false,
            canDeleteContent:
              permissions.find((p) => p.feature_id === "content_delete")
                ?.permission === "1"
                ? true
                : false,
            canPerformAllActivity:
              permissions.find((p) => p.feature_id === "content_all")
                ?.permission === "1"
                ? true
                : false,
          },
        };
      }

      return {
        ...state,
        user_permissions: permission,
      };
    }

    case sessionTypes.LOG_OUT:
      return initialState;

    case sessionTypes.CLEAR_LOCAL_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
