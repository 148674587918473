import { listingReducer } from "../reducers/utils/listingReducer";
import { getAllPermissions } from "../../services/Permissions";

const { reducer, actions, types } = listingReducer({
  name: `permissions`,
  getApi: getAllPermissions,
});

const permissions = reducer;
export { permissions, actions, types };
