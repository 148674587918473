import { listingReducer } from "../reducers/utils/listingReducer";
import { getAllMedia } from "../../services/mediaPartners";

const { reducer, actions, types } = listingReducer({
  name: `mediaPartners`,
  getApi: getAllMedia,
});

const mediaPartners = reducer;
export { mediaPartners, actions, types };
