import { post, get, put, deletee } from "./index";

export const getAllUploads = (params) => {
  const request = {
    subUrl: `/uploadlisting`,
    params,
  };
  return get(request);
};

export const getUploadByID = (id) => {
  const request = {
    subUrl: `/getUploadById/${id}`,
    isJSON: true,
  };
  return get(request);
};

export const uploadFile = (data) => {
  const request = {
    subUrl: `/upload`,
    data,
  };
  return post(request);
};

export const updateUploads = (data, id) => {
  const request = {
    subUrl: `/upload_edit/${id}`,
    data,
  };
  return post(request);
};

export const downloadImage = (id) => {
  const request = {
    subUrl: `/download-image/${id}`,
  };
  return get(request);
};

export const updateUser = (data) => {
  const request = {
    subUrl: `/userstatus`,
    data,
  };
  return post(request);
};

export const deleteUpload = (id) => {
  const request = {
    subUrl: `/upload/${id}`,
    isJSON: true,
  };
  return deletee(request);
};

export const updateUserStatus = (data) => {
  const request = {
    subUrl: `/userstatus`,
    data,
  };
  return post(request);
};

export const getAllStates = (params) => {
  const request = {
    subUrl: `/statelist`,
    params,
  };
  return get(request);
};

export const getAlllocationlist = (params) => {
  const request = {
    subUrl: `/locationlist`,
    params,
  };
  return get(request);
};
