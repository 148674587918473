import axios from "axios";
import { loadState } from "../app/localStorage";
import store from "../redux/store";
import { errorToast } from "../utilities/utilities";
import { appRoutesConst } from "../app/navigation";

export const authTokenKey = "Authorization";
// const dstore = store.dis
export const responseHeaderKeys = {
  pagination: "x-pagination",
};

const apiBaseUrls = {
  prod: "https://selfservice.thestorywallcafe.com/api",
  dev: "https://selfservice.thestorywallcafe.com/api",
  stage: "https://selfservice.thestorywallcafe.com/api",
};

// const apiBaseUrls = {
//   prod: "https://api.vynus.in/mtnapi",
//   dev: "https://api.vynus.in/mtnapi",
//   stage: "https://api.vynus.in/mtnapi",
// };

export const API_URL = "https://selfservice.thestorywallcafe.com/api";

// const apiBaseUrls = {
//   prod: "http://13.127.5.195",
//   dev: "http://13.127.5.195",
//   stage: "http://13.127.5.195",
// };

const webSocketURL = {
  prod: "wss://api.alldaydr.com",
  dev: "wss://dev-api.alldaydr.com",
  stage: "wss://staging-api.alldaydr.com",
};

export const appConfig = {
  mockEnv: false,
};

const react_app_env = {
  prod: "prod",
  dev: "dev",
  stage: "stage",
};
// console.log(process.env.REACT_APP_ENV);

let baseUrl = apiBaseUrls.dev;
let baseWebSocketURL = webSocketURL.dev;
if (process.env.REACT_APP_ENV === react_app_env.stage) {
  baseUrl = apiBaseUrls.stage;
  baseWebSocketURL = webSocketURL.stage;
} else if (process.env.REACT_APP_ENV === react_app_env.prod) {
  baseUrl = apiBaseUrls.prod;
  baseWebSocketURL = webSocketURL.prod;
}
export const BASE_URL = baseUrl;

export const get = (request) => {
  return commonFetch({ method: "get", ...request });
};

export const post = (request) => {
  return commonFetch({ method: "post", ...request });
};

export const patch = (request) => {
  return commonFetch({ method: "patch", ...request });
};

export const put = (request) => {
  return commonFetch({ method: "put", ...request });
};

export const deletee = (request) => {
  return commonFetch({ method: "delete", ...request });
};
const commonFetch = (request) => {
  const {
    subUrl,
    method,
    data = {},
    params = {},
    headers = {},
    isOnlyURL,
    baseURLPassed,
    isJSON,
    ad_token, //! Active Directory Token or  Auth Access Token (From ClientAuthAPP)
    // resetToken,
    // authAccessToken ,
  } = request;
  let url = getFullUrl(subUrl, baseURLPassed);
  let passedHeaders = headers;
  console.log("AD Token Passed : ", ad_token);
  let commonHeaders = getCommonHeaders(isJSON, ad_token);

  if (isOnlyURL) {
    url = subUrl;
    passedHeaders = {};
    commonHeaders = {};
  }

  return axios
    .request({
      method,
      url,
      data,
      headers: { ...commonHeaders, ...passedHeaders },
      onUploadProgress: (data) => {
        // console.log(
        //   "Upload progress: ",
        //   Math.round((100 * data?.loaded) / data?.total)
        // );
        let progress = Math.round((100 * data?.loaded) / data?.total);
        store.dispatch({
          type: "SET_UPLOAD_PROGRESS",
          payload: progress,
        });

        // return progress;
      },
      params,
      mode: "no-cors",
    })
    .then((response) => {
      console.log("response API: ", response?.data?.message);
      // saveAPILogs(true, response?.data?.message || "Success");
      if (isOnlyURL) {
        // if (response) {
        //   return Promise.resolve(response);
        // } else {
        //   return Promise.reject(response);
        // }
        return response;
      } else {
        if (response.data.status) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      }
    })
    .catch((e) => {
      console.log("error response API: ", e);
      saveAPILogs(false, e.data || e.response.data || "something went wrong");

      // handleUnauthorizedResponses(e.data || e.response.data);
      return e;
    });
};

const saveAPILogs = (isSuccess, errorData) => {
  console.log("save APILogs: ", errorData);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = {
    key: isSuccess ? "success" : "error",
    value: [{ Message: errorData }],
  };

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
  };

  fetch(`${API_URL}/logger`, requestOptions)
    .then((response) => response.json())
    .then((result) => console.log(result))
    .catch((error) => console.log("API Error Logged Successfully", error));
};

/**
 * Handles unauthorized responses.
 *
 * @param {object} response - The response object.
 */
const handleUnauthorizedResponses = (response) => {
  const isUnauthorized = response.status_code === "705" ||
    response.statusCode === 500 ||
    response.status_code === 401;

  if (isUnauthorized) {
    console.log("Unauthorized response");
    errorToast({
      content: "Token Time Expire",
    });
    localStorage.clear();
    store.dispatch({ type: "CLEAR_LOCAL_STATE" });
    window.location.href = appRoutesConst.autoLogout;
  }
};


const handleResponseStatus = (response) => {
  console.log("response status: ", response);
  if (response && response.status === true) {
    return true;
  }
  return false;
};

const getCommonHeaders = (isJSON, ad_token) => {
  const state = loadState();
  let token = null;
  if (state && state.localStore && state.localStore.token) {
    token = state.localStore.token;
  }

  console.log("AD TOKEN IN COMMON HEADERS", ad_token);
  return {
    "Content-Type": isJSON ? "application/json" : "multipart/form-data",
    [authTokenKey]: ad_token ? `${ad_token}` : `${token}`,
    
  };
};
export const getFullUrl = (url, baseUrlPassed) => {
  if (baseUrlPassed) {
    return `${baseUrlPassed}${url}`;
  } else {
    return `${baseUrl}${url}`;
  }
};

export const getAnyTokenFromState = (state) => {
  const { localStore, session } = state;
  return {
    [authTokenKey]: localStore.token || session.token,
  };
};
export { baseWebSocketURL };
