import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { appRoutesConst } from "../app/navigation";

class RestrictedRoute extends Component {
  render() {
    const { token, roleID } = this.props;
    if (token) {
      if (roleID === "1") {
        return <Navigate to={appRoutesConst.adminHome} />;
      } else {
        return <Navigate to={appRoutesConst.adminUpload} />;
      }
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  token: _.get(state, "localStore.token", ""),
  roleID: _.get(state, "localStore.user.roleid", ""),
});

export default connect(mapStateToProps)(RestrictedRoute);
